<template>
  <div v-if="show" class="selectPerson" @click="show = false">
    <div class="dialogCotent" @click.stop="">
      <div class="title">
        请选择载入数据来源
      </div>
      <div class="dialogSelect flex">
        <div class="left" @click="todoFromBook">
          <div class="icon">
            <a-icon type="usergroup-add" />
          </div>
          <div>通讯录</div>
        </div>
        <div class="right left" @click="toSelectLabel">
          <div class="icon">
            <a-icon type="solution" />
          </div>
          <div>标签</div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      show: false,
      key: '',
      index: -1
    }
  },
  methods: {
    todoFromBook() {
      this.$emit('selectTodoMsg', {
        type: 'book',
        data: {
          key: this.key,
          index: this.index
        }
      })
      this.show = false
    },
    toSelectLabel() {
      this.$emit('selectTodoMsg', {
        type: 'label',
        data: {
          key: this.key,
          index: this.index
        }
      })
      this.show = false
    },
    open({ key, index }) {
      this.show = true
      this.key = key
      this.index = index
    }
  }
}
</script>
<style lang="less" scoped>
.selectPerson {
  background-color: rgba(0, 0, 0, .7);
  position: fixed;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 1;
  top: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-size: 18px;
  .flex {
    display: flex;
    align-items: center;
  }
  .dialogCotent {
    width: 80%;
    padding: 12px;
    border-radius: 8px;
    background-color: #fff;
    .title {
      font-size: 20px;
      font-weight: bold;
      margin-bottom: 15px;
    }
    .dialogSelect {
      width: 100%;
      .left {
        flex: 1;
      }
      .icon {
        font-size: 40px;
      }
    }
  }
}
</style>
